/* You can add global styles to this file, and also import other style files */
@import "ngx-toastr/toastr";
@import "leaflet/dist/leaflet.css";
@import "leaflet.markercluster/dist/MarkerCluster.css";
@import "leaflet.markercluster/dist/MarkerCluster.Default.css";
@import "./scss/theming/avelon-theming";
@import "./scss/theming/material-theming";
@import "./scss/theming/theme-aliases";
@import "./scss/theming/typography";
@import "variables";

@import "./scss/font";
@import "./scss/material-symbols";
@import "./scss/alcedo-font";
@import "./scss/alcedo-font-compounds";
@import "./scss/animation";
@import "./scss/cg-busy-overrides";
@import "./scss/css-variables";
@import "./scss/drag-over/drag-over";
@import "./scss/input";
@import "./scss/layout-flex";
@import "./scss/layout-grid";
@import "./scss/leaflet";
@import "./scss/list-item";
@import "./scss/material-overrides/cdk-virtual-scroll-overrides";
@import "./scss/material-overrides/mat-autocomplete-overrides";
@import "./scss/material-overrides/mat-button-overrides";
@import "./scss/material-overrides/mat-card-overrides";
@import "./scss/material-overrides/mat-checkbox-overrides";
@import "./scss/material-overrides/mat-chip-overrides";
@import "./scss/material-overrides/mat-datepicker-overrides";
@import "./scss/material-overrides/mat-dialog-overrides";
@import "./scss/material-overrides/mat-divider-overrides";
@import "./scss/material-overrides/mat-expansion-panel-overrides";
@import "./scss/material-overrides/mat-form-field-overrides";
@import "./scss/material-overrides/mat-icon-overrides";
@import "./scss/material-overrides/mat-list-overrides";
@import "./scss/material-overrides/mat-menu-overrides";
@import "./scss/material-overrides/mat-progress-bar-overrides";
@import "./scss/material-overrides/mat-progress-spinner-overrides";
@import "./scss/material-overrides/mat-radio-button-overrides";
@import "./scss/material-overrides/mat-select-overrides";
@import "./scss/material-overrides/mat-sidenav-overrides";
@import "./scss/material-overrides/mat-slide-toggle-overrides";
@import "./scss/material-overrides/mat-slider-overrides";
@import "./scss/material-overrides/mat-toolbar-overrides";
@import "./scss/material-overrides/mat-tooltip-overrides";
@import "./scss/material-overrides/mat-badge-overrides";
@import "./scss/material-overrides/material-overrides-utils";
@import "./scss/action-buttons";
@import "./scss/scrollbar";
@import "./scss/outdated";
@import "./scss/toastr-overrides";
@import "./scss/widgets";
@import "./scss/root-only/root-only";

html,
body {
  font-size: $font-size;
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
  overflow: hidden;
}

* {
  @include default-scrollbar(var(--primary-60), var(--primary-80));
  scrollbar-color: var(--primary-60) transparent;
  scrollbar-width: thin;
}

button,
select,
html,
html body, // tiny-mce tries to override our font
textarea,
input {
  font-family: $font-family;
}

select,
button,
textarea,
input {
  font-size: $font-size;
}

.text-no-wrap {
  white-space: nowrap;
}

.highlight {
  background: var(--yellow);
  border-radius: 2px;
  color: var(--on-yellow);
  margin: 0 1px;
  padding: 0 1px;
}

.overflow-auto {
  overflow: auto;
}

.overflow-auto-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.overflow-auto-y {
  overflow-x: hidden;
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-hidden-x {
  overflow-x: hidden;
}

.overflow-hidden-y {
  overflow-y: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.font-bold {
  font-weight: bold;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-justify {
  text-align: justify;
}

.visibility-hidden {
  visibility: hidden;
}

.hidden,
.display-none {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

.opacity-50 {
  opacity: 0.5;
}

mat-icon {
  transition: transform 0.2s;

  &.flip-h {
    transform: translateZ(1px) rotateY(180deg);
    // fix Safari bug https://stackoverflow.com/questions/18146511/bug-in-css3-rotatey-transition-on-safari
  }

  &.flip-v {
    transform: translateZ(1px) rotateX(180deg);
    // fix Safari bug https://stackoverflow.com/questions/18146511/bug-in-css3-rotatey-transition-on-safari
  }

  &.rotate-cw {
    transform: rotate(90deg);
  }

  &.rotate-ccw {
    transform: rotate(-90deg);
  }

  &.icon-hue {
    color: var(--icon-hue);
  }
}

.show-on-hover {
  opacity: 0;
}

*:hover > .show-on-hover {
  opacity: 1;
}

.material-label {
  display: block;
  font-size: $label-font-size;
  font-weight: 400;
  color: var(--label-text);
}

.color-box {
  width: 2 * $base-size;
  min-width: 2 * $base-size;
  height: 2 * $base-size;
  border-radius: 2px;
  margin: 1.5 * $base-padding;
  display: inline-block;
}

.chevron::after {
  content: "❯";
  display: inline-block;
  margin: 0 0.5em;
  opacity: 0.2;
}

.octet:not(:last-child)::after {
  content: ":";
}

input[type="number"]:not(:hover), // ALCEDO-175400
input[type="number"]:disabled {
  -moz-appearance: textfield;
}

body,
body.avelon-dark-theme {
  &.reporting-engine,
  &.full-screen-mode {
    .exclude-from-reporting {
      display: none;
    }

    alc-client {
      grid-template-rows: 100%;
    }

    alc-schematic-view .schematic-widget alc-schematic {
      height: 100% !important;
    }

    .alcedo-svg-chart g.title > text {
      padding: 0 !important;
    }
  }
}

.avelon-theme.avelon-dark-theme {
  button.mat-mdc-unelevated-button.root-only {
    color: white !important;

    mat-icon {
      color: white;
    }
  }
}
