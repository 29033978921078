@import "scss/root-only/root-only.mixin";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  --mdc-checkbox-selected-focus-icon-color: var(--tertiary-10);
  --mdc-checkbox-selected-hover-icon-color: var(--tertiary-10);
  --mdc-checkbox-selected-icon-color: var(--tertiary-10);
  --mdc-checkbox-selected-focus-state-layer-color: var(--tertiary-10);
  --mdc-checkbox-selected-hover-state-layer-color: var(--tertiary-10);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--on-primary);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--tertiary-10);
  --mdc-checkbox-unselected-icon-color: rgba(var(--on-primary-rgb), 0.54);

  .mat-mdc-checkbox {
    &.root-only {
      @include root-only()
    }
  }

  mat-checkbox:has(input:disabled) {
    --mdc-checkbox-unselected-hover-icon-color: var(--mdc-checkbox-disabled-unselected-icon-color);
    pointer-events: auto;

    * {
      cursor: not-allowed;
    }
  }
}

.avelon-theme:not(.avelon-dark-theme) {
  --mat-checkbox-label-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.87);
  --mdc-checkbox-selected-checkmark-color: var(--avelon-theme-light-on-tertiary);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
}

.avelon-theme.avelon-dark-theme {
  --mat-checkbox-disabled-label-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.5);
  --mdc-checkbox-disabled-selected-checkmark-color: var(--avelon-theme-dark-on-primary);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
}
